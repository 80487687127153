@import 'settings',
        'normalize',
        'styles',
        'slick.scss',
        'outdatedbrowser.scss',
        'animate.scss';

#banner{
    .slider{
        img + img{
            display: none;
        }

        .slick-slide{
            display: block;
        }
    }
}


.slider .slick-slide:focus{
	outline: 0;
}


.slider .news-item + .news-item{
    display: none;

    &.slick-slide{
        display: block;
    }
}

#logos-box .slider > div + div{
    display: none;

    &.slick-slide{
        display: block;
    }
}


.banner-news .slick-slide > img{
	height: auto !important;
}


.animation{
	visibility: hidden;
	animation-duration: 1.4s;
}

.animation + .animation{
	animation-delay: 0.1s;
}

.animation + .animation + .animation{
	animation-delay: 0.2s;
}

.animation + .animation + .animation + .animation{
	animation-delay: 0.3s;
}

.animation + .animation + .animation + .animation + .animation{
	animation-delay: 0.4s;
}

.no-csstransitions .animation,
.animation.animated{
	visibility: visible;
}


#nav-mobile{
	position: fixed;
	top: 0;
	left: -100%;
	z-index: 1;
	display: block;
    overflow-x: hidden;
    overflow-y: auto;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.7);
	opacity: 0;
	transition: opacity 0.6s, left 0.6s;
}

#nav-mobile:after{
	position: fixed;
}

#nav-mobile.is-visible{
	left: 0;
	opacity: 1;
}


#nav-mobile .nav-mobile-container{
	position: relative;
	width: 100%;
	height: calc(100% - 70px);
	border-top: solid 70px #0e9e4b;

}

#nav-mobile .nav-mobile-container > ul{
    position: relative;
    //transform: translateX(-90%);
    left: 0;
    //transition: transform 0.6s;
    transition: left 0.6s;

    &.depth-1{
        //transform: translateX(-100%);
        left: -100%;
    }
}

#nav-mobile ul{
	position: relative;
	display: block;
	margin: 0;
	padding: 0;
	background-color: #92ba39;
	list-style-type: none;
	//transition: transform 0.6s;
    transition: left 0.6s;
}



#nav-mobile ul li{
	border-bottom: solid 1px #a7c760;
	line-height: normal;

    &.orange{
        border-bottom-color: lighten($orange, 20%);
        background-color: $orange;
    }
}

#nav-mobile ul li a{
	display: block;
	padding-right: 1rem;
	padding-left: 1rem;
	height: 3rem;
	color: #fff;
	text-align: left;
	text-decoration: none;
	text-transform: uppercase;
	line-height: normal;

}

#nav-mobile ul li a:before{
	display: inline-block;
	height: 100%;
	content: '';
	vertical-align: middle;
}

#nav-mobile .menu-item-has-children > a{
	display: inline-block;
	margin-right: -0.25em;
	padding-right: 0;
	width: calc(100% - 4rem);
	vertical-align: top;
}

#nav-mobile .menu-item-has-children > .btn-show-children{
	display: inline-block;
    margin-right: -0.25em;
	//float: right;
	width: 3rem;
	height: 3rem;
	border-left: solid 1px #a7c760;
	color: #fff;
	vertical-align: top;
	font-size: 1.4rem;
	line-height: 3rem;
}

#nav-mobile .menu-item-has-children > .btn-back{
	position: absolute;
	top: -70px;
	left: 100%;
	width: 70px;
	height: 70px;
	background-color: #0e9e4b;
	color: #fff;
	font-size: 2rem;
	line-height: 70px;
	opacity: 0;
	transition: opacity 0.4s;

}

#nav-mobile .children.is-active + .btn-show-children + .btn-back{
	opacity: 1;
}

#nav-mobile .children.is-active{
    left: 0;
	//transition: transform 0.6s;
	//transform: translateX(0);
    transition: left 0.6s;
}

#nav-mobile ul ul{
	position: absolute;
	top: 0;
	left: 100%;
	//z-index: -1;
    z-index: 1;
    width: 100%;
	transform: translateX(100%);
}

/*#nav-mobile ul.depth-1{
	transform: translateX(-100%);
}*/




#nav-desktop{
    > ul{
        margin: 0;
        padding: 0;
        list-style-type: none;

        > li{
            position: relative;
            display: inline-block;
            padding-left: 20px;
            font-size: 16px;
            font-size: 16px;

            > a{
                color: black;
                text-decoration: none;
            }

            > .children{
                position: absolute;
                top: 100%;
                left: 50%;
                z-index: 1;
                display: none;
                margin: 0;
                margin-top: -30px;
                margin-left: -90px;
                padding: 0;
                width: 200px;
                background-color: $green;
                list-style-type: none;



                &:before{
                    position: absolute;
                    top: -10px;
                    left: 50%;
                    display: block;
                    margin-left: -10px;
                    width: 0;
                    height: 0;
                    border-width: 0 10px 10px 10px;
                    border-style: solid;
                    border-color: transparent transparent $green transparent;
                    content: '';
                }

                > li{
                    margin: 0;
                    padding: 0;
                    border-top: solid 1px #a7c760;
                    line-height: normal;

                    &.is-active > a{
                        font-weight: bold;
                    }

                    &:first-child{
                        border-top: none;
                    }

                    > a{
                        padding: 10px 20px;
                        color: #fff;
                        text-decoration: none;

                        &:hover{
                            background-color: $green-dark;
                        }
                    }
                }

            }

            &:hover{
                > a{
                    color: #0e9e4b;
                }

                > .children{
                    display: block;
                }
            }

            &.is-active > a,
            &.current-page-ancestor > a{
                color: #0e9e4b;
                font-weight: 600;
            }
        }
    }
}


.btn-close-menu{
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2;
	display: block;
	width: 70px;
	height: 70px;
	color: #fff;
	font-size: 1.6rem;
	line-height: 70px;
}



.accordeon-title{
	cursor: pointer;
}

.accordeon-content{
	overflow: hidden;
	max-height: 0;
	transition: max-height 0.5s;
}

.accordeon-item.is-active .accordeon-content{
	max-height: 60rem;
}

section#news-box{
    .news-home{
        min-height: 225px;
        height: auto;
    }

    .news-item{
        height: auto;
    }

    .text-news-home{
        max-height: none;
    }
}


/*.news-list .news-item{
    margin-left: 1rem;
    width: calc(50% - 0.5rem);

    &:nth-child(odd){
        margin-left: 0;
    }
}*/




.bloc-item{
    &:nth-child(even){
        background-color: $gray;
    }
}




/** 23 // FORM/*/

fieldset{
    margin: 2rem 0;
    padding: 2rem;
    border: solid 1px #ccc;
    background-color: $gray;
}

legend{
    margin-top: 1em;
    margin-bottom: 1em;
    padding: 0.5em 1em;
    background-color: $green-light;
    color: #004500;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1em;
}

input[type="text"],
input[type="email"],
select,
textarea{
    display: inline-block;
    box-sizing: border-box;
    padding: 0.73em 1.5em;
    width: 100%;
    border: none;
    border: solid 1px #ccc;
    border-radius: 0;
    background-color: #fff;
    box-shadow: none;
    vertical-align: middle;
    font-size: 1em;
    font-family: inherit;
    transition: box-shadow .5s, border-color .25s ease-in-out;

    -webkit-appearance: none;
    -moz-appearance: none;
}

label{

}


select{
    padding-right: 1em;
    background-image: url('../img/icon-select.svg');
    background-position: right center;
    background-origin: content-box;
    background-size: 0.6rem auto;
    background-repeat: no-repeat;
}

textarea{
    height: 8rem;
}



.field-container{
    display: inline-block;
    margin-right: -0.25em;
    margin-bottom: 1rem;
    margin-left: 2rem;
    width: calc(50% - 1rem);
    vertical-align: top;

    &:nth-child(even){
        margin-left: 0;
    }

    &.large{
        display: block;
        margin-left: 0;
        width: 100%;
    }

    label{
        display: inline-block;
        margin-right: -0.25em;
        padding-top: 0.6em;
        padding-bottom: 0.3em;
        width: 8rem;
        vertical-align: top;
    }

    input[type="text"],
    input[type="email"],
    select,
    textarea{
        display: inline-block;
        margin-right: -0.25em;
        width: calc(100% - 8rem);
        vertical-align: top;
    }
}

.parsley-errors-list{
    margin: 0;
    margin-left: 8rem;
    padding: 0;
    color: $color-error;
    list-style-type: none;
    text-align: left;
    font-size: 0.8em;


    > li{
        margin: 0;
        padding: 0.3em 1em;
        background-color: lighten($color-error, 40%);
    }
}

.message{
    margin-bottom: 1rem;
    padding: 1em 1em;
    text-align: left;

    &.success{
        background-color: lighten($color-success, 40%);
        color: $color-success;
    }

    &.error{
        background-color: lighten($color-error, 40%);
        color: $color-error;
    }
}


button{
    display: inline-block;
    padding: 1.2em 1.6em;
    border: none;
    border-radius: 0;
	background-color: $orange;
    color: #fff;
	vertical-align: middle;
    vertical-align: middle;
	vertical-align: middle;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
	font-weight: bold;
    font-size: 1rem;
    line-height: 1;
    cursor: pointer;

    -webkit-appearance: none;
}


.btn-animated{
    transition: background-color 0.6s;

    &:hover{
        background-color: $green-dark !important;
    }
}


.contact-form{
    margin: 3rem 0;
}



@media screen and (max-width: 800px){
    .news-list .news-item{
        margin-left: 0;
        width: 100%;
    }

    fieldset{
        padding-right: 1rem;
        padding-left: 1rem;
    }

    .field-container{


        label{
            display: block;
            margin-right: 0;
            padding-top: 0;
            width: 100%;
        }

        input[type="text"],
        input[type="email"],
        select,
        textarea{
            display: block;
            margin-right: 0;
            width: 100%;
            vertical-align: top;
        }
    }

    .parsley-errors-list{
        margin-left: 0;
    }
}


@media screen and (max-width: 550px){
    .field-container{
        display: block;
        margin-right: 0;
        margin-left: 0;
        width: 100%;
    }
}



/** NEW // TEAM/*/
#team {
	padding: 90px 0;

    @media screen and (max-width: 550px){
        padding: 20px 0;
    }

    a{
    	text-decoration: none;
    }

    .slide {
        padding: 0 1rem;

        @media screen and (max-width: 550px){
            padding: 0;
        }

        &:focus {
            outline: 0;
        }
    }

    .member {
    	display: block;
    	background-color: #bae35e;
    	color: white;
    	text-decoration: none;
    	font-weight: 600;
    	font-size: 17px;
        cursor: pointer;

        img {
            width: 100%;
        }

        div {
        	padding: 30px;



            span {
            	display: block;
            	padding: 5px 0;

                a {
                	color: white;
                }
            }
        }

        .main-info {
            min-height: 58px;
        }

        .member-more {
            overflow: hidden;
            padding: 0 30px 0 30px;
            height: 0;
            transition: height 0.4s, padding-bottom 0.4s;

            &.is-visible {
                padding-bottom: 30px;
                height: 70px;
            }
        }
    }

    .slick-dots {
        margin: 0;
        padding: 1rem 0;
        list-style-type: none;
        text-align: center;

        > li {
            display: inline-block;
            overflow: hidden;
            margin: 0 10px;
            width: 16px;
            height: 16px;
            border-radius: 8px;
            background-color: #ccc;
            text-indent: -9999px;
            cursor: pointer;

            &.slick-active {
                background-color: #f2853f;
            }

            button {
                display: none;
            }
        }
    }
}
