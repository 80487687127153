$use-fadeInUp: true;
$use-fadeInLeft: true;
$use-fadeInLeftBig: true;
$use-fadeInRight: true;
$use-fadeInDown: true;

$black: #3e3c3c;
$green: #92ba39;
$green-light: #c7d051;
$green-dark: #0e9e4b;
$orange: #f2853f;
$gray: #f6f6f6;

$color-success: #43AC6A;
$color-error: #f04124;
