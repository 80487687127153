
/** 01// GENERAL/*/
/** 02 // GRID/*/
/** 03 // HEADER/*/
/** 04 // HEADER / FIRST LINE/*/
/** 05 // HEADER / SECOND LINE/*/
/** 06 // HEADER / RESPONSIVE-LINE/*/
/** 07 // BANNER/*/
/** 08// SERVICES/*/
/** 09 // PRESENTATION/*/
/** 10 // CONTACT/*/
/** 11 // TIPS/*/
/** 12 // NEWS-BOX/*/
/** 13 // LOGOS-BOX/*/
/** 14 // FOOTER/*/
/** 15 // H1-CONTENT/*/
/** 16 // PHOTOS-BOX/*/
/** 17 // LOGOS-INSURANCE/*/
/** 18 // SERVICE-LIST/*/
/** 19 // ACCORDEON/*/
/** 20 // CRELAN-LINK/*/
/** 21 // CITY-LIST/*/
/** 22 // CONTACT/*/

/** 01// GENERAL/*/
body {
	font-family: "Open Sans";
	-webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: 100%;
	color: #3e3c3c;
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
	font-size: 15px;
	line-height: 24px;
}
img {
	display: block;
	max-width: 100%;
}
.clearfix {clear: both;}
a{
	transition: all 0.17s ease-in-out;
    -moz-transition: all 0.17s ease-in-out;
    -webkit-transition: all 0.17s ease-in-out;
    -o-transition: all 0.17s ease-in-out;
}

p {
	margin: 0 0 30px 0;
	text-align: justify;
}

p a {
	color: black;
	text-decoration: none;
	border-bottom: 1px solid #cececf;
}

.border-none {border-bottom: 0;}

p a:hover {
	opacity: 0.6;
}

h1 {
	font-size: 16px;
	font-weight: 700;
}

h2 {
	font-size: 18px;
	line-height: 25px;
	margin: 0 0 20px 0;
}

h3 {
	font-size: 20px;
	line-height: 28px;
	margin: 0 0 20px 0;
}

b, strong {
	font-weight: 600;
}
.btn .fa {
	font-weight: 800;
	font-size: 16px;
	margin-left: 5px;
}
.wrapper {
	max-width: 1200px;
	margin: 0 auto;
}
.btn {
	text-align: center;
}
.btn a {
	background-color: #f2853f;
	color: white;
	font-weight: 900;
	text-decoration: none;
	text-transform: uppercase;
	display: block;
	font-size: 14px;
	padding: 10px 20px;
	transition: all 0.17s ease-in-out;
    -moz-transition: all 0.17s ease-in-out;
    -webkit-transition: all 0.17s ease-in-out;
    -o-transition: all 0.17s ease-in-out;
}

.btn a:hover {
	background-color: #ff8c3e;
	color: #fff;
	-webkit-transition: all .25s ease;
	-moz-transition: all .25s ease;
	-ms-transition: all .25s ease;
	-o-transition: all .25s ease;
	transition: all .25s ease;
	text-shadow: 0 0 4px rgba(255,255,255,0.6);
}

/** 02 // GRID/*/
.col1-3 {width: 28.3333333333%; float: left; margin: 0 2.5%;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
}
.col2-3 {width: 61.6666666666%; float: left; margin: 0 2.5%;
-webkit-box-sizing: border-box;
 -moz-box-sizing: border-box;
box-sizing: border-box;
}
.col1-4 {width: 25%; float: left; padding: 0 2.5%;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
 box-sizing: border-box;
}
.col2-4 {width: 50%; float: left; padding: 0 2.5%;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
}
.col4-4 {width: 100%; padding: 0 2.5%;
-webkit-box-sizing: border-box;
-moz-box-sizing: border-box;
box-sizing: border-box;
}

/** 03 // HEADER/*/

		/** 04 // HEADER / FIRST LINE/*/
		section#first-line {
		background-color: #92ba39;
		background-image: url(../img/patern.png);
		background-position: -10px;
		}

			/** back-home/*/
			section#first-line div.back-home {
				float: left;
				line-height: 55px;
				text-align: center;
				width: 55px;
				height: 55px;
			}
			section#first-line div.back-home a {
				color: #bae35e;
				font-size: 20px;
				display: block;
				transition: all 0.17s ease-in-out;
                -moz-transition: all 0.17s ease-in-out;
                -webkit-transition: all 0.17s ease-in-out;
                -o-transition: all 0.17s ease-in-out;
                background-color: #0e9e4b;
			}

			section#first-line div.back-home a:hover {
				background-color: #10b455;
			    -webkit-transition: all .25s ease;
			    -moz-transition: all .25s ease;
			    -ms-transition: all .25s ease;
			    -o-transition: all .25s ease;
			    transition: all .25s ease;
			    text-shadow: 0 0 4px rgba(255,255,255,0.6);
			}
			/** second-menu/*/
			section#first-line nav.second-menu {
				height: 55px;
				float: right;
			}
			section#first-line nav.second-menu ul {
				margin: 0;
				padding: 0;
				font-size: 0;
			}
			section#first-line nav.second-menu ul li {
				list-style: none;
				display: inline-block;
				line-height: 55px;
				margin-left: 20px;
			}
			section#first-line nav.second-menu ul li:last-child {
				height: 100%;
				background-color: #0e9e4b;
			}
			section#first-line nav.second-menu ul li a{
				color: white;
				text-decoration: none;
				text-transform: uppercase;
				font-weight: 800;
				font-size: 14px;
				display: block;
				padding: 0 30px;
			}
			section#first-line nav.second-menu ul li:last-child a:hover{
			    background-color: #10b455;
			    color: #fff;
			    -webkit-transition: all .25s ease;
			    -moz-transition: all .25s ease;
			    -ms-transition: all .25s ease;
			    -o-transition: all .25s ease;
			    transition: all .25s ease;
			    text-shadow: 0 0 4px rgba(255,255,255,0.6);
			}
			section#first-line nav.second-menu ul li:last-child a {
				color: white;
			}
			section#first-line nav.second-menu ul li:first-child a {
				padding: 0;
			}
			section#first-line nav.second-menu ul li .fa{
				font-size: 16px;
				padding-right: 5px;
			}

			section#first-line nav.second-menu ul li a{
				text-decoration: none;
			}

			section#first-line nav.second-menu ul li.facebook-icon .fa{
				font-size: 20px;
			}


		/** 05 // HEADER / SECOND LINE/*/
		/** main-menu/*/
		section#second-line a{
			display: block;
		}
		section#second-line .col1-3 {
		padding: 0;
		width: 33.333333333%;
		}
		section#second-line .col2-3 {
		padding: 0;
		float: right;
		margin: 0;
		}
		section#second-line .logo {
			margin: 35px 0;
		}
		section#second-line nav{
			float: left;
			line-height: 154px
		}
			section#second-line .contact-btn {
				float: right;
			}
			section#second-line .contact-btn .btn{
				margin-top: 43px;
				text-align: center;
				line-height: 50px;
			}

			/** main-menu
			section#second-line nav ul {
				margin: 0;
				padding: 0;
			}
			section#second-line nav ul li{
				display: inline-block;
				font-size: 16px;
				list-style: none;
				padding-left: 20px;
			}
			section#second-line nav ul li a{
				color: black;
				text-decoration: none;
			}

			section#second-line nav ul li a:hover{
				color: #0e9e4b;
			}

			section#second-line nav ul li.is-active > a,
			section#second-line nav ul li.current-page-ancestor > a{
				font-weight: 600;
				color: #0e9e4b;
			}/*/

		/** 06 // HEADER / RESPONSIVE-LINE/*/
		section#responsive-line {
			background-color: #0e9e4b;
			height: 70px;
			line-height: 70px;
			text-align: center;
			position: relative;
			display: none;
		}


		section#responsive-line .white-logo img{
			width: 270px;
			margin: 0 auto;
			padding-top: 7px;
		}

		section#responsive-line .white-logo img.small-white-logo{
			width: 50px;
			margin: 0 auto;
			padding-top: 7px;
			display: none;
		}

		section#responsive-line .contact-responsive{
			position: absolute;
			top: 0;
			right: 0;
			width: 70px;
			height: 100%;
		}

		section#responsive-line .contact-responsive a{
			color: white;
			text-decoration: none;
			font-size: 25px;
			display: block;
		}

			/** hamburger-menu/*/
			section#responsive-line .hamburger-menu {
				width: 70px;
				text-align: center;
				position: absolute;
				top: 0;
				height: 100%;
				left: 0;
			}

			section#responsive-line .hamburger-menu a{
				color: white;
				font-size: 30px;
				display: block;
			}
			/** hamburger-menu/*/

/** 07 // BANNER/*/
#banner {
	position: relative;
}
#banner img {
	width: 100%;
}
#banner .home-h1 {
	background-color: rgba(146,186,57,0.8);
	position: absolute;
	bottom: 0;
	width: 100%;
}
#banner h1 {
	display: block;
	font-size: 19px;
	color: white;
	padding: 35px 0;
	font-weight: 600;
	letter-spacing: 1px;
	line-height: 22px;
}

/** 08// SERVICES/*/
section#services {
	margin: 20px auto;
}
section#services div a{
	display: block;
	text-decoration: none;
	text-transform: uppercase;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	color: #f2853f;
}

section#services div{
	transition: all 0.17s ease-in-out;
    -moz-transition: all 0.17s ease-in-out;
    -webkit-transition: all 0.17s ease-in-out;
    -o-transition: all 0.17s ease-in-out;
}

section#services div a:hover div{
	background-color: #ffc8a2;
}

section#services div a:hover span{
	font-weight: 700;
}

section#services div.icon{
	border: 3px solid #f2853f;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	width: 75px;
	height: 75px;
	margin: 20px auto 20px auto;
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
	box-sizing: border-box;
}

section#services .icon-service-middle{
	border-right: 1px solid #c5c7c9;
	border-left: 1px solid #c5c7c9;
}

section#services .insurance-item{
	border-left: 1px solid grey;
	border-right: 1px solid grey;
}

section#services .col1-3 div+div{
	margin-bottom: 20px
}

/** 09 // PRESENTATION/*/
section#presentation {
	padding: 90px 0 90px 0;
}

section#presentation.home-presentation{
	background-color: #f6f6f6;
}


section#presentation .h2-home {
	padding: 0 0 70px 0;
	margin: 0;
	text-align: center;
	font-weight: 800;
	font-size: 22px;
	line-height: 26px;
}

section#presentation h2 {
	padding: 0 0 15px 0;
	margin: 0;
	font-weight: 600;
	font-size: 18px;
	line-height: 26px;
}

section#presentation .columns {
	margin: 0;
	-webkit-column-count: 2;
	-moz-column-count:2;
	column-count: 2;
	-webkit-column-gap: 50px;
	-moz-column-gap:50px;
	column-gap: 50px;
}

section#presentation .columns img {
	display: inline-block;
	margin-right: 40px;
	margin-bottom: 20px;
}

.single section#presentation .columns img {
	margin-bottom: 0;
}


section#presentation li:before {
   font-family: 'FontAwesome';
	content: '\f00c';
	margin:0 5px 0 -15px;
	color: black;
	font-size: 20px;
}

section#presentation li {
   padding-bottom: 15px;
   list-style: none;
   list-style-position: inside;
   margin-left: -25px;
}

section#presentation .btn {
	float: right;
}

section#presentation.cities .btn {
	margin-top: 20px;
	width: 100%;
}
section#presentation .fa {
	color: #92ba39;
	font-size: 18px;
	margin: 0 5px 0 10px;
}

section#presentation .btn .fa {
	color: white;
}

section#presentation.cities {
	padding: 0;
}

section#presentation .city {
	padding: 90px 0;
}

section#presentation .city h2 {
	text-transform: uppercase;
}

section#presentation .city p {
	margin: 0 0 15px 0;
}

section#presentation .city .fa {
	margin-left: 0;
}

section#presentation.cities .city:nth-child(even) {
	background-color: #f6f6f6;
}

section#presentation.cities p a {
	color: black;
}

/** 10 // CONTACT/*/
section#contact-btn {
	background-color: #c7d051;
	background-image: url(../img/patern.png);
	background-position: -10px;
	height: 70px;
	margin-bottom: 90px;
}

section#contact-btn .wrapper{
	height: 100%;
}

section#contact-btn ul {
	margin: 0;
	padding: 0;
	font-size: 0;
	height: 100%;
}

section#contact-btn ul li {
	display: inline-block;
	width: 33.3%;
	text-align: center;
	height: 100%;
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

section#contact-btn ul li:nth-child(2) {
	border-right: 1px solid white;
	border-left : 1px solid white;
		-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

section#contact-btn ul li a {
	display: block;
	background-color: #92ba39;
	text-decoration: none;
	color: white;
	text-transform: uppercase;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	height: 100%;
	line-height: 70px;
	transition: all 0.17s ease-in-out;
    -moz-transition: all 0.17s ease-in-out;
    -webkit-transition: all 0.17s ease-in-out;
    -o-transition: all 0.17s ease-in-out;
}

section#contact-btn ul li a:hover {
	background-color: #9ec93e;
	color: #fff;
	-webkit-transition: all .25s ease;
	-moz-transition: all .25s ease;
	-ms-transition: all .25s ease;
	-o-transition: all .25s ease;
	transition: all .25s ease;
	text-shadow: 0 0 4px rgba(255,255,255,0.6);
}

section#contact-btn ul li .fa {
	margin-right: 15px;
}


/** 11 // TIPS/*/

.page-id-17 section#tips{
	padding: 0 0 70px 0;
}

section#tips{
	padding: 70px 0;
	background-color: #f6f6f6;
}

section#tips div {
	text-align: center;
	margin: 0 auto;
}

section#tips span {
	margin-top: 20px;
	display: inline-block;
}

section#tips a {
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	color: #92ba39;
	font-weight: 600;
}

section#tips a:hover div{
	background-color: #d5f392;
	-webkit-transition: all .25s ease;
	-moz-transition: all .25s ease;
	-ms-transition: all .25s ease;
	-o-transition: all .25s ease;
	transition: all .25s ease;
	text-shadow: 0 0 4px rgba(255,255,255,0.6);
}

section#tips.tips-contact {
	background-color: white;
}

.home section#tips.tips-contact {
	background-color: #f6f6f6;
}

section#tips .icon {
	width: 70px;
	height: 70px;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	text-align: center;
	background-color: #e1f3b9;
}

section#tips .mobile-item {
	background-image: url(../img/mobile-item.png);
	background-repeat: no-repeat;
	background-position: center;
}

section#tips .sinistre-item {
	background-image: url(../img/sinistre-item.png);
	background-repeat: no-repeat;
	background-position: center;
}

section#tips .quote-item {
	background-image: url(../img/quote-item.png);
	background-repeat: no-repeat;
	background-position: center;
}

section#tips .ckeckup-item {
	background-image: url(../img/checkup-item.png);
	background-repeat: no-repeat;
	background-position: center;
}


/** 12 // NEWS-BOX/*/
section#news-box {
	padding: 0 0 90px 0;
}
section#news-box.news-list {
	padding: 90px 0;
}

section#news-box .news-home{
	height: 225px;
	position: relative;
	background-color: white;
	background-color: #f6f6f6;
}

section#news-box .news-item{
	min-height: 165px;
	padding: 20px;
	text-align: right;
}

section#news-box .img-news-home {
	float: left;
	width: 23.2222222%;
}

section#news-box .text-news-home {
	float: left;
	width: 67%;
	margin-left: 9.25922592%;
	text-align: justify;
	max-height: 105px;
	overflow: hidden;
	margin-bottom: 20px;
}

section#news-box h4{
	margin: 0;
	padding: 0 0 5px 0;
	color: #3e3c3c;
}

section#news-box a{
	text-decoration: none
}

section#news-box .btn{
	background-color: #c7d051;
	display: inline-block;
}

section#news-box .banner-news{
	height: 225px;
}

section#news-box .banner-news a{
	display: block;
	width: 100%;
	height: 100%;
}

section#news-box .banner-news img{
	height: 100%;
}

/** 13 // LOGOS-BOX/*/
section#logos-box {
	padding: 50px 0;
}

section#logos-box h4{
	font-size: 15px;
	margin: 0;
	padding: 40px 0;
	text-align: center;
}

section#logos-box .col1-3{
	background-color: white;
	padding: 0;
	margin: 0 2.5%;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;}

section#logos-box img{
	margin: 0 auto;
}

section#logos-box a{
	text-decoration: none;
	color: black;
}

section#logos-box .box{
	margin-bottom: 40px;
	height: 90px;
	line-height: 90px;
}

/** 14 // FOOTER/*/
footer {
	background-color: black;
	text-align: center;
	padding: 60px 0 0 0;
}

footer a {
	color: white;
	font-size: 12px;

}

footer span {
	display: inline-block;
	color: white;
	font-size: 12px;
}

footer .footer-facebook {
	background-color: white;
	padding: 20px 0;
	font-weight: 600;
	margin-top: 60px
}


/** 15 // H1-CONTENT/*/
section#h1-content {
	background-color: #c7d051;
}

section#h1-content h1 {
	color: #0e9e4b;
	background-color: rgba(255,255,255, 0.8);
	padding: 15px 40px!important;
	margin: 20px 0!important;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 1px;
	line-height: 21px;
}

/** 16 // PHOTOS-BOX/*/
section#photos-box {
	padding: 50px 0;
	background-color: #f6f6f6;
}


/** 17 // LOGOS-INSURANCE/*/
section#logos-insurance ul{
	font-size: 0;
	padding : 90px 0 20px 0;
}

section#logos-insurance li{
	display: inline-block;
	width: 20%;
	list-style: none;
	margin: 0 0 60px 0;
}

section#logos-insurance img {
	margin: 0 auto;
	width: 90%;
}


/** 18 // SERVICE-LIST/*/

section#services .bank-item {
	background-image: url(../img/bank-item.png);
	background-repeat: no-repeat;
	background-position: center;
}

section#services .insurance-item {
	background-image: url(../img/insurance-item.png);
	background-repeat: no-repeat;
	background-position: center;
}

section#services .fisc-item {
	background-image: url(../img/fisc-item.png);
	background-repeat: no-repeat;
	background-position: center;
}


section#service-list {
	background-color: #f6f6f6;
	padding: 90px 0;
}

section#service-list a {
	background-color: #bae35e;
	text-align: center;
	font-size: 17px;
	font-weight: 600;
	color: white;
	display: block;
	text-decoration: none;
	text-transform: uppercase;
	padding: 40px 0;

}

section#service-list a div {
	width: 70px;
	height: 70px;
	background-color: #eaf7cf;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	margin: 0 auto 30px auto;
	line-height: 70px;
}

section#service-list a div.people-icon {
	background-image: url(../img/people.png);
    background-repeat: no-repeat;
    background-position: center;
}

section#service-list a div.entreprise-icon {
	background-image: url(../img/entreprise.png);
    background-repeat: no-repeat;
    background-position: center;
}

section#service-list a div.payer-icon {
	background-image: url(../img/payer.png);
    background-repeat: no-repeat;
    background-position: center;
}

section#service-list a div.epargner-icon {
	background-image: url(../img/epargner.png);
    background-repeat: no-repeat;
    background-position: center;
}

section#service-list a div.emprunter-icon {
	background-image: url(../img/emprunter.png);
    background-repeat: no-repeat;
    background-position: center;
}


/** 19 // ACCORDEON/*/

section#accordeon .bloc {
	border-bottom: 1px solid #d6d6d6;
	margin: 20px 0;
}

section#accordeon .wrapper {
	padding: 40px 0;
}

section#accordeon h2 {
	margin: 0;
	padding: 0;
	font-weight: 600;
	text-transform: uppercase;
	font-size: 16px;
	line-height: 20px;
	margin-bottom: 20px;
	color: #92ba39;
}
section#accordeon h3 {
	margin: 0;
	padding: 0;
	font-weight: 600;
	font-size: 17px;
	margin-bottom: 20px;
}

section#accordeon h4 {
	margin: 0;
	padding: 0;
	font-weight: 800;
	text-transform: uppercase;
	font-size: 14px;
	color: #0e9e4b;
	margin-bottom: 20px;
}

section#accordeon span {
	font-weight: 500;
	text-transform: lowercase;
}

section#accordeon .fa {
	margin-right: 15px;
}

section#accordeon .btn {
	width: 100%;
	margin-bottom: 20px;
}

section#accordeon .emprunter-btn .btn {
	float: right;
	width: 320px;
	margin-left: 40px;
}

section#accordeon .emprunter-entreprises {
	background-color: #f6f6f6;
}

section#accordeon .emprunter-independants {
	background-color: white;
}

section#accordeon .emprunter-agriculteurs {
	background-color: #f6f6f6;
}


/** 20 // CRELAN-LINK/*/

section#crelan-link {
	padding: 90px 0;
	background-color: #f6f6f6;
}

section#crelan-link h3 {
	font-size: 16px;
	color: #89b84c;
}

section#crelan-link ul {
	margin: 0;
	padding: 0;
}

section#crelan-link ul li {
	display: inline-block;
	margin-right: 20px;
}

section#crelan-link ul li a {
	color: black;
	font-weight: 600;
}

section#crelan-link .fa {
	margin-right: 10px
}


/** 21 // CITY-LIST/*/
section#city-list a {
	background-color: #bae35e;
	text-align: center;
	font-size: 17px;
	font-weight: 600;
	color: white;
	display: block;
	text-decoration: none;
	text-transform: uppercase;
	padding: 70px 0;
}

section#city-list {
	padding: 90px 0;
	background-color: #f6f6f6;
}


/** 22 // SUB-MENU/*/
section#sub-menu {
	background-color: #92ba39;
}

section#sub-menu ul {
	font-size: 0;
	margin: 0;
	padding: 0;
}

section#sub-menu ul li{
	display: inline-block;
	list-style: none;
	padding: 20px 30px 20px 0;
}

section#sub-menu ul li a{
	font-size: 16px;
	font-weight: 600;
	text-decoration: none;
	color: white;
	text-transform: uppercase;
}

.map {
	background-color: #f6f6f6;
	padding: 90px 0;
}

#map_canvas {
	height: 400px;
}


/** 23 // CONTACT IMG /*/

section#contact-img {
background-color: #f6f6f6;
background-image: url(../img/patern.png);
background-position: -10px;
margin-bottom: 90px;
}

#contact-img .titles {
	margin: 100px 0 60px 0;
	text-align: center;
}
#contact-img ul {overflow: hidden;}
#contact-img ul li {
	float: left;
	overflow:hidden;
	width: 30%;
	margin: 40px 0;
}

.home #contact-img ul li {
	margin: 0;
}
#contact-img ul li.center {margin: 40px 5%;}
.home #contact-img ul li.center {margin: 0 5% 40px 5%;}
#contact-img li a {display: block; text-decoration: none;}
#contact-img .caption-text span {
	font-size: 20px;
	text-transform: uppercase;
	font-weight: 600;
	margin: 0 auto;
	position: absolute;
	top: 50%; left: 50%;
	width: 90%;
	z-index: 200;
	padding-bottom: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.caption-style {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
}
.caption-style ul {overflow: hidden;}
.caption-style li {
	float: left;
	padding: 0px;
	position: relative;
	overflow: hidden;
}
.caption-style li:hover .caption {opacity: 1;}
.caption-style li:hover img {opacity: 1;
	transform: scale(1.02,1.02);
	-webkit-transform:scale(1.02,1.02);
	-moz-transform:scale(1.02,1.02);
	-ms-transform:scale(1.02,1.02);
	-o-transform:scale(1.02,1.02);}
.caption-style img {
	margin: 0px;
	padding: 0px;
	float: left;
	z-index: 4;
}
.caption-style p {text-align: center;}
.caption-style .caption {cursor: pointer;position: absolute;opacity: 0;width: 100%;height: 100%;
	-webkit-transition:all 0.45s ease-in-out;
	-moz-transition:all 0.45s ease-in-out;
	-o-transition:all 0.45s ease-in-out;
	-ms-transition:all 0.45s ease-in-out;
	transition:all 0.45s ease-in-out;
}
.caption-style img {
	-webkit-transition:all 0.25s ease-in-out;
	-moz-transition:all 0.25s ease-in-out;
	-o-transition:all 0.25s ease-in-out;
	-ms-transition:all 0.25s ease-in-out;
	transition:all 0.25s ease-in-out;
}
.caption-style .blur {
	height: 100%;
	width: 100%;
	z-index: 5;
	position: absolute;
	background-color: rgba(255, 255, 255, 0.7);
}
.caption-style .caption-text {
	z-index: 10;
	color: #0e9e4b;
	width: 100%;
	height: 100%;
	text-align: center;
}






/** // RESPONSIVE/*/


/** 1230/*/
@media screen and (max-width: 1230px){
	/** 01 // GENERAL/*/
	.wrapper {margin: 0 30px;}
}

/** 1100/*/
@media screen and (max-width: 1110px){
	/** 04 // HEADER / FIRST LINE/*/
	section#first-line {display: none;}

	/** 05 // HEADER / SECOND LINE/*/
	section#second-line {display: none;}

	/** 06 // HEADER / RESPONSIVE-LINE/*/
	section#responsive-line {display: block;}
}

/** 900/*/
@media screen and (max-width: 900px){

	/** 02 // GRID/*/
	#news-box .col2-4 {width: 100%;}
	.banner-news.col2-4{margin-top: 30px; padding: 0;}

}

/** 800/*/
@media screen and (max-width: 800px){

	/** 02 // GRID/*/
	.col2-4 {width: 100%;}

	/** 07 // BANNER/*/
	section#banner h1 {font-size: 17px; padding: 20px 0; line-height: 21px;}

	/** 08// SERVICES/*/
	section#service-list {padding: 40px 0;}
	section#service-list .col2-4 {padding: 20px 0;}

	/** 09 // PRESENTATION/*/
	section#presentation .columns {
		-webkit-column-count: 1;
		-moz-column-count:1;
		column-count: 1;
	}
	section#presentation h2 {text-align: left}
	section#presentation .columns {padding: 0 0 30px 0;}
	section#presentation.cities .btn {margin-bottom: 60px}

	/** 12 // NEWS-BOX/*/
	section#news-box .banner-news {margin-top: 30px}


	/** 17 // LOGOS-INSURANCE/*/
	section#logos-insurance li {width: 33.333%;}
}


/** 550/*/
@media screen and (max-width: 550px){

	/** 01 // GENERAL/*/
	section#logos-box .col1-3 {margin: 10px 0;}
	section#logos-box .box {height: auto;}
	.btn a {font-size: 13px;}
	section#h1-content h1 {padding: 15px 20px!important;}

	/** 02 // GRID/*/
	.col1-4 {width: 50%;}
	.col1-3 {width: 100%; margin: 0;}
	#services .col1-3 {margin: 10px 0; border-bottom: 1px solid #f2853f}
	#service-list .col1-3 {margin: 20px 0;}

	/** 07 // BANNER/*/
	section#banner img {display: none;}
	section#banner .home-h1 {background-color: rgba(146,186,57,1); position: static;}

	/** 09 // PRESENTATION/*/
	section#contact-btn ul li {width: 100%;}
	section#contact-btn {height: auto;}
	section#contact-btn ul li:nth-child(2) {
		border-left: 0;
		border-right: 0;
		border-top: 1px solid white;
		border-bottom: 1px solid white;
	}
	.home #contact-img ul li.center {margin: 30px 0;}
	#contact-img ul li.center {margin: 30px 0;}
	section#presentation .city {padding: 40px 0;}


	/** 08// SERVICES/*/
	section#services { margin: 0; }
	section#services div.icon {display: none;}
	section#services .col1-3 div+div {margin: 0; padding: 40px 0; font-weight: 800; background-color: rgba(146,186,57,0.8); border-bottom: 1px solid white; color: white;}

	/** 09 // PRESENTATION/*/
	section#presentation {padding: 40px 0 20px 0;}
	section#presentation .h2-home {padding-bottom: 30px}
	section#presentation .columns img {width: 40%; margin-right: 10px}
	.single section#presentation .columns img {width: 100%; margin-right: 0}
	section#presentation .btn {width: 100%;}

	/** 11 // TIPS/*/
	section#tips {padding: 60px 0 0 0;}
	section#tips span {padding-bottom: 60px;}

	/** 12 // NEWS-BOX/*/
	section#news-box .img-news-home {display: none;}
	section#news-box .btn {width: 100%;}
	section#news-box .text-news-home {width: 100%; margin: 0; float: none;}
	section#news-box .btn {margin-top: 20px;}

	/** 13 // LOGOS-BOX/*/
	section#logos-box .box {margin-bottom: 20px}
	section#logos-box h4 {padding: 20px 0;}


	/** 16 // PHOTOS-BOX/*/
	section#photos-box .col1-3 {width: 100%; margin: 20px 0;}

	/** 19 // ACCORDEON/*/
	section#accordeon .emprunter-btn .btn {width: 100%; margin-left: 0; float: none;}

	/** 20 // CRELAN-LINK/*/
	section#crelan-link {padding: 60px 0; text-align: center;}
	section#crelan-link img {margin: 0 auto;}
	section#crelan-link ul li {display: block; margin-bottom: 15px ; text-align: center}
	section#crelan-link ul li:last-child {margin-bottom: 0}

	/** 21 // CITY-LIST/*/
	section#city-list {margin: 20px 0}
	section#city-list {padding: 40px 0;}

	/** 23 // CONTACT IMG /*/

	#contact-img ul li {
		float: none;
		width: 100%;
		margin: 40px 0;
	}

	#contact-img ul li.center{}

}


/** 460/*/
@media screen and (max-width: 460px){
	/** 06 // HEADER / RESPONSIVE-LINE/*/
	section#responsive-line .white-logo img {display: none;}
	section#responsive-line .white-logo img.small-white-logo {display: block;}

	/** 09 // PRESENTATION/*/
	section#presentation .btn {width: 100%;}

	section#sub-menu ul li {
		display: block;
		text-align: center;
		padding: 10px 0;
	}


}

/** 250/*/
@media screen and (max-width: 250px){

	/** 02 // GRID/*/
	.col1-4 {width: 100%;}
}
